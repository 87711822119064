import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, Switch } from "react-router-dom";
import {jwtDecode} from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import { Provider } from "react-redux";
import store from "./store";

import Navbar from "./components/layout/Navbar";
import Landing from "./components/layout/Landing";
import Register from "./components/auth/Register";
import Login from "./components/auth/Login";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Dashboard from "./components/dashboard/Dashboard";

import { useLocation } from "react-router-dom";
import axios from "axios";

// Check for token to keep user logged in
if (localStorage.jwtToken) {
    // Set auth token header auth
    const token = localStorage.jwtToken;
    setAuthToken(token);
    // Decode token and get user info and exp
    const decoded = jwtDecode(token);
    // Set user and isAuthenticated
    store.dispatch(setCurrentUser(decoded));
    // Check for expired token
    const currentTime = Date.now() / 1000; // to get in milliseconds
    if (decoded.exp < currentTime) {
        // Logout user
        store.dispatch(logoutUser());
        // Redirect to login
        window.location.href = "./login";
    }
}

const Tracking = () => {
    const location = useLocation();

    useEffect(() => {
        console.log(navigator.userAgent);
        console.log(location.pathname);
        const cookie_data = {
            contact_id: '1',
            device_id: '153621125253'
        };
        axios.post('http://localhost:8000/api/tracking', cookie_data);
        
    }, [location]);

    return (
        <div className="App">
            <Navbar />
            <Routes>
                <Route exact path="/" element={<Landing />} />
                <Route exact path="/register" element={<Register />} />
                <Route exact path="/login" element={<Login />} />
                <Route exact path='/dashboard' element={<PrivateRoute/>}>
                    <Route exact path='/dashboard' element={<Dashboard/>}/>
                </Route>
            </Routes>
        </div>
    );
}
const App = () => {
    return (
        <Provider store={store}>
            <Router>
                <Tracking />
            </Router>
        </Provider>
    );
}

export default App;